import {getOrder} from "../../store/api-action";
import {useAppDispatch, useAppSelector} from "../../hooks/redux/redux";
import {useParams} from "react-router-dom";
import {useEffect} from "react";
import priceStringify from "../../global_functions/price_stringify";

export default function OrderPage () {
    const dispatcher = useAppDispatch();
    const { id } = useParams();

    useEffect(()=>{
        if (id) {
            dispatcher(getOrder(id));
        }
    }, []);

    const data = useAppSelector(state => state.oneOrder);

    if (!data) {
        return <div className="main_container">Loading...</div>;
    }

    const payment = data.payment;
    const order = data.order;
    const cart = data.cart;
    const delivery = data.delivery;

    let paymentStatus;
    if (payment.status) {
        paymentStatus = <h4><span className="black">Статус оплаты - </span><span className="dot green" /> Successfull</h4>
    } else {
        paymentStatus = <h4><span className="black">Статус оплаты - </span><span className="dot red" /> Error</h4>;
    }

    let deliveryStatus;
    if (order.status) {
        deliveryStatus = <h4><span className="black">Статус отправления</span> - <span className="dot green" /> Отправленно</h4>;
    } else {
        deliveryStatus = <h4><span className="black">Статус отправления</span> - <span className="dot red" /> Не отправлено</h4>;
    }

    return (
        <div className="main_container" style={{textAlign: 'center'}}>

            <h1 className="black">Номер заказа - {order.uniq_id}</h1>
            <a href={"/orders/update/" + order.uniq_id}>Обновить</a>
            <br/>
            <hr/>
            <br/>

            {paymentStatus}
            <h4><span className="black">Cкидка составила - </span>{priceStringify(order.discount)} ₽</h4>
            <h4><span className="black">Оплатил клиент - </span>{priceStringify(payment.sum)} ₽</h4>
            <h4><span className="black">Остаток после комиссии кассы - </span>{priceStringify(payment.got_sum)} ₽</h4>
            <h4><span className="black">Стоимость доставки фактическая- </span>{delivery.actual_price ? priceStringify(delivery.actual_price) : 0} ₽</h4>
            <h4><span className="black">Стоимость доставки оплаченная - </span>{priceStringify(delivery.presumable_price)} ₽</h4>
            <h4><span className="black">Налоги - </span>{payment.tax_receipt_url === null ?
                <span><span className="dot red"/>Неуплачены</span> :
                <span><span className="dot green"/> <a href={payment.tax_receipt_url}>Чек отправлен</a></span>}</h4>
            <h4><span className="black">Сумма налога - </span>{priceStringify(payment.tax_sum)} ₽</h4>

            <br/>
            <hr/>
            <br/>

            <h2 className="black">Доставка</h2>
            {deliveryStatus}
            <h4><span className="black">Трек номер - </span>{delivery.track_num}</h4>
            <h4><span className="black">Способ доставки - </span>{delivery.delivery_type}</h4>
            <h4><span className="black">Тип ПВЗ - </span>{delivery.pvz_type}</h4>
            <h4><span className="black">Регион - </span>{delivery.region}</h4>
            <h4><span className="black">Город - </span>{delivery.city}</h4>
            <h4><span className="black">Адрес - </span>{delivery.address}</h4>
            <h4><span className="black">Индекс - </span>{delivery.postal_code}</h4>
            <h4><span className="black">Комментарий - </span>{delivery.delivery_description}</h4>
            <h4><span className="black">Время - </span>{delivery.created_at}</h4>
            {
                delivery.sent_date ? <h4><span className="black">Время - </span>{delivery.created_at}</h4> : <div/>
            }

            <br/>
            <hr/>
            <br/>

            <h2 className="black">Получатель</h2>
            <h4><span className="black">Имя - </span>{order.first_name}</h4>
            <h4><span className="black">Фамилия - </span>{order.last_name}</h4>
            <h4><span className="black">Отчество - </span>{order.middle_name}</h4>
            <h4><span className="black">Телефон - </span> {order.phone}</h4>
            <h4><span className="black">Почта - </span>{order.email}</h4>

            <br/>
            <hr/>
            <br/>

            <h2 className="black">Покупки</h2>
            {
                cart.designs.map((item) => {
                    return (
                        <div>
                            <a href={"/design/" + item.design_id}>
                                <img src={item.photo} width={200} alt=""/>
                                <h4>{item.design_name}</h4>
                            </a>
                            <h4><span className="black">Одежда - </span> {item.clothes_name} </h4>
                            <h4><span className="black">Размер одежды - </span> {item.clothes_size} </h4>
                            <h4><span className="black">Размер дизайна - </span> {item.design_size} </h4>
                            <h4><span className="black">Расположение - </span> {item.design_place} </h4>
                            <h4><span className="black">Цена - </span> {priceStringify(item.price)} ₽ </h4>
                            <h4><span className="black">Дата создания - </span> {item.created_at} </h4>
                            <br/>
                        </div>
                    );
                })
            }
            {
                cart.products.map((item) => {
                    return (
                        <div>
                            <a href={"/product/" + item.product_id}>
                                <img src={item.photo} width={200} alt=""/>
                                <h4>{item.design_name}</h4>
                            </a>
                            <h4><span className="black">Одежда - </span> {item.clothes_name} </h4>
                            <h4><span className="black">Размер одежды - </span> {item.clothes_size} </h4>
                            <h4><span className="black">Цена - </span> {priceStringify(item.price)} ₽</h4>
                            <h4><span className="black">Дата создания - </span> {item.created_at} </h4>
                            <br/>
                        </div>
                    );
                })
            }

        </div>
    );
}
