import {createReducer} from '@reduxjs/toolkit';
import {StateType} from "../types/types";
import {
  setAllOrder, setOneOrder, setClothes, setNewDesign, setDesignToClothing, loadCatalog, loadDesign, loadProduct
} from "./action";
const initialState: StateType = {
  allOrders: [],
  oneOffer: undefined,
  oneOrder: undefined,
  clothes: [],
  designToClothes: [],
  newDesignInfo: undefined,
  catalog: undefined,
  design: undefined
};

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(setAllOrder, (state, action)=>{
    state.allOrders = action.payload;
  });

  builder.addCase(setOneOrder, (state, action) => {
    state.oneOrder = action.payload;
  })

  builder.addCase(setClothes, (state, action) => {
    state.clothes = action.payload;
  })

  builder.addCase(setNewDesign, (state, action) => {
    state.newDesignInfo = action.payload;
  })

  builder.addCase(setDesignToClothing,  (state, action) => {
    state.designToClothes = action.payload;
    console.log(state.designToClothes);
  })

  builder.addCase(loadCatalog, (state, action) => {
    state.catalog = action.payload;
  })

  builder.addCase(loadDesign, (state, action) => {
    state.design = action.payload;
  })

  builder.addCase(loadProduct, (state, action) => {
    state.product = action.payload;
  })
});

