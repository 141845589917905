export default function priceStringify(price: number) {

    const priceString = String(price);
    const dotIndex = priceString.indexOf('.');

    const priceIntPartString = dotIndex > -1 ? priceString.slice(0, dotIndex) : priceString;
    const priceFloatPartString = dotIndex > -1 ? priceString.slice(dotIndex) : "";

    const groupSize = 3;
    const groupCount = parseInt(String(priceIntPartString.length / groupSize)) // Определяем количество групп по три символа
    const smallestGroup = priceIntPartString.length % groupSize; // наименьшая группа


    if (groupCount === 1 && smallestGroup >= 1) {
        return priceIntPartString.slice(0, smallestGroup) + " " + priceIntPartString.slice(smallestGroup) + priceFloatPartString;
    }

    if (groupCount === 2 && smallestGroup === 0) {
        return priceIntPartString.slice(0, groupSize) + " " + priceIntPartString.slice(groupSize) + priceFloatPartString;
    }

    if (groupCount === 2 && smallestGroup >= 1) {
        return priceIntPartString.slice(0, smallestGroup) + " " + priceIntPartString.slice(smallestGroup, groupSize + smallestGroup) + " " + priceIntPartString.slice(smallestGroup + groupSize) + priceFloatPartString;
    }

    return priceString;
}