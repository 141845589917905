import {useAppDispatch, useAppSelector} from "../../hooks/redux/redux";
import OrderPreview from "../../components/order_preview/OrderPreview";
import {store} from "../../store";
import {updateAllOrders} from "../../store/api-action";

export default function FirstPage() {
    const data = useAppSelector(state => state.allOrders );
    const dispatcher = useAppDispatch();

    if (data.length) {
        return (
            <div className="main_container">
                <table className="order">
                    <tr>
                        <td className="black">Статус оплаты</td>
                        <td className="black">UNIQID</td>
                        <td className="black">Оплатил чел</td>
                        <td className="black">Статус чека</td>
                        <td className="black">Статус заказа</td>
                        <td className="black">Трек номер</td>
                        <td className="black">Дата</td>
                    </tr>
                    {
                        data.map((order) => <OrderPreview order={order} />)
                    }
                </table>
            </div>
        );
    } else {
        dispatcher(updateAllOrders());
    }

    return <h1 className="p-5 black">Loading...</h1>

}
