import {AxiosInstance} from 'axios';
import {createAsyncThunk} from '@reduxjs/toolkit';
import {AppDispatch, designToClothesSettings, State, UpdateType} from '../types/types';
import {
    loadCatalog, loadDesign, loadProduct,
    setAllOrder,
    setDesignToClothing,
    setClothes,
    setNewDesign,
    setOneOrder
} from "./action";
import {ApiRoute, GlobalAppRoutes} from "../const/const";
import {saveToken} from "../api/api";
import appNotification from "../global_functions/notify";

export const updateAllOrders = createAsyncThunk<void, undefined, {
    dispatch: AppDispatch,
    state: State,
    extra: AxiosInstance
}>(
    'data/getAll',
    async (none, {dispatch, extra: api}) => {
        const { data } = await api.get(ApiRoute.allOrders);
        dispatch(setAllOrder(data));
    },
);

export const getOrder = createAsyncThunk<void, string , {
    dispatch: AppDispatch,
    state: State,
    extra: AxiosInstance
}>(
    'data/getAll',
    async (id, {dispatch, extra: api}) => {
        const { data } = await api.get(ApiRoute.oneOrder + id);
        dispatch(setOneOrder(data));
    },
);

export const sendOrderConfig = createAsyncThunk<void, UpdateType, {
    dispatch: AppDispatch,
    state: State,
    extra: AxiosInstance
}>(
    'data/updateOrder',
    async (updates, {dispatch, extra: api}) => {
        await api.post(ApiRoute.updateOrder, 'update=' + JSON.stringify(updates));
        window.location.assign(GlobalAppRoutes.order + '/' + updates.uniqid);
    },
);

export const login = createAsyncThunk<void, {login: string, password: string}, {
    dispatch: AppDispatch,
    state: State,
    extra: AxiosInstance
}>(
    'data/updateOrder',
    async (auth, {dispatch, extra: api}) => {
        const { data } = await api.post(ApiRoute.login, auth);
        if (data.session !== null) {
            saveToken(data.session);
            window.location.assign(GlobalAppRoutes.order);
        }
    },
);

export const createDesign = createAsyncThunk<void, { data: HTMLFormElement, catalog_id: number }, {
    dispatch: AppDispatch,
    state: State,
    extra: AxiosInstance
}>(
    'design/create',
    async (form, {dispatch, extra: api}) => {
        const { data } = await api.post(ApiRoute.createDesign + form.catalog_id, new FormData(form.data));
        if (data !== 'false') {
            dispatch(setNewDesign(data));
        }
    },
);


export const createProduct = createAsyncThunk<void, { data: HTMLFormElement, catalog_id: number }, {
    dispatch: AppDispatch,
    state: State,
    extra: AxiosInstance
}>(
    'product/create',
    async (form, {dispatch, extra: api}) => {
        api.post(ApiRoute.createProduct + form.catalog_id, new FormData(form.data)).then(
            (data)=> { window.location.assign(GlobalAppRoutes.product + data.data.id) }
        )
    },
);

export const getDesign = createAsyncThunk<void, {design_id: number } , {
    dispatch: AppDispatch,
    state: State,
    extra: AxiosInstance
}>(
    'data/get/design',
    async ({design_id}, {dispatch, extra: api}) => {
        api.get(ApiRoute.design + design_id).then(
            result => dispatch(loadDesign( result.data )),
            error => appNotification("error", error.message)
        );
    }
);

export const getProduct = createAsyncThunk<void, {product_id: number } , {
    dispatch: AppDispatch,
    state: State,
    extra: AxiosInstance
}>(
    'data/product',
    async ({product_id}, {dispatch, extra: api}) => {
        api.get(ApiRoute.product + product_id).then(
            result => dispatch(loadProduct( result.data )),
            error => appNotification("error", error.message)
        );
    }
);

export const createCatalog = createAsyncThunk<void, { data: HTMLFormElement, catalog_id: number }, {
    dispatch: AppDispatch,
    state: State,
    extra: AxiosInstance
}>(
    'data/createOffer',
    async (form, {dispatch, extra: api}) => {
        const { data } = await api.post(ApiRoute.createCatalog + form.catalog_id, new FormData(form.data));
        if (data) {
            window.location.pathname = GlobalAppRoutes.catalog.default + form.catalog_id;
        }
    },
);


export const loadAllClothing = createAsyncThunk<void, undefined, {
    dispatch: AppDispatch,
    state: State,
    extra: AxiosInstance
}>(
    'data/getAllClothing',
    async (none, {dispatch, extra: api}) => {
        const { data } = await api.get(ApiRoute.allClothing);
        dispatch(setClothes(data));
    },
);

export const sendToGenerator = createAsyncThunk<void, designToClothesSettings[] , {
    dispatch: AppDispatch,
    state: State,
    extra: AxiosInstance
}>(
    'data/generate-all',
    async (config, {dispatch, extra: api}) => {
        const { data } = await api.post(ApiRoute.generateAllClothes, config);
        console.log(data);
        dispatch(setDesignToClothing([]));
        dispatch(setClothes([]));
        dispatch(setNewDesign(undefined));
    },
);

export const getCatalog = createAsyncThunk<void, {catalog_id: number } , {
    dispatch: AppDispatch,
    state: State,
    extra: AxiosInstance
}>(
    'data/catalog',
    async ({catalog_id}, {dispatch, extra: api}) => {
        const { data } = await api.get(ApiRoute.catalog + catalog_id);
        dispatch(loadCatalog( data ));
        console.log(data);
    },
);

export const deleteItem = createAsyncThunk<void, {product_id?: number, design_id?: number} , {
    dispatch: AppDispatch,
    state: State,
    extra: AxiosInstance
}>(
    'data/delete',
    async (params, {dispatch, extra: api}) => {
        if (params.product_id) {
            await api.delete(ApiRoute.product + params.product_id).then(
                ()=> { window.location.pathname = GlobalAppRoutes.catalog.main },
                (error) => { appNotification("error", "Не удалось удалить продукт =>" + error.message) }
            );
        } else if (params.design_id) {
            await api.delete(ApiRoute.design + params.design_id).then(
                ()=> { window.location.pathname = GlobalAppRoutes.catalog.main },
                (error) => { appNotification("error", "Не удалось удалить дизайн =>" + error.message) }
            );
        }

    },
);

export const changeView = createAsyncThunk<void, {product_id?: number, design_id?: number} , {
    dispatch: AppDispatch,
    state: State,
    extra: AxiosInstance
}>(
    'data/visible',
    async (params, {dispatch, extra: api}) => {
        if (params.product_id) {
            await api.put(ApiRoute.updateProductVisible + params.product_id).then(
                ()=> { window.location.reload() },
                (error) => { appNotification("error", "Не удалось изменить продукт =>" + error.message) }
            );
        } else if (params.design_id) {
            await api.put(ApiRoute.updateDesignVisible + params.design_id).then(
                ()=> { window.location.reload() },
                (error) => { appNotification("error", "Не удалось изменить дизайн =>" + error.message) }
            );
        }

    },
);
