import React, { DOMAttributes } from 'react';
import classNames from 'classnames';
import {IconType} from "../../types/types";
// @ts-ignore
import { ReactComponent as Success } from '../../system_icons/success.svg';
// @ts-ignore
import { ReactComponent as Error } from '../../system_icons/error.svg';
// @ts-ignore
import { ReactComponent as Waiting } from '../../system_icons/wait.svg';
// @ts-ignore
import { ReactComponent as Loader } from '../../system_icons/loader.svg';
// @ts-ignore
import { ReactComponent as Page404 } from '../../system_icons/404.svg';
// @ts-ignore
import { ReactComponent as Page404mobile } from '../../system_icons/404mobile.svg';


const IconTypes = new Map([
    ['success', <Success />],
    ['error', <Error />],
    ['waiting', <Waiting />],
    ['loader', <Loader />],
    ['404-page', <Page404 />],
    ['404-small-page', <Page404mobile />],
]);

const getIcon = (type: IconType): JSX.Element => IconTypes.get(type) as JSX.Element;

export interface IconProps extends DOMAttributes<HTMLDivElement> {
    className?: string;
    type: IconType;
}

export const Svg: React.FC<IconProps> = ({className, type}) => {
    return (<div className={classNames("Icon", className)}>
        {
            getIcon(type)
        }
    </div>);
}
