import {useState} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks/redux/redux";
import {setDesignToClothing} from "../../store/action";
import {designToClothesSettings, DesignToClothing} from "../../types/types";
import {sendToGenerator} from "../../store/api-action";
import ImageResizer from "../image_resizer/image_resizer";

export default function CreateImage (data: DesignToClothing) {

    const dispatcher = useAppDispatch();

    const designToClothesSettings = useAppSelector((select) => select.designToClothes );

    const [settings, setSettings] = useState({width: 180, height: 180, margin_left: 310, margin_top: 180});

    function showNextCard (id:number) {
        document.querySelectorAll('.create-page-step').forEach((elem) => {
            elem.classList.add('hidden');
        });
        document.querySelector('#step-' + id)?.classList.remove('hidden');
    }

    function changeSize (data:{width?: number, height?: number, top?: number, left?: number}) {
        setSettings({
            width: data.width? data.width : settings.width,
            height: data.height? data.height : settings.height,
            margin_left: data.left ? data.left : settings.margin_left,
            margin_top: data.top ? data.top : settings.margin_top,
        });
    }

    function saveToStorage (save: { ml: number; mt: number; width: number; height: number, place: string, price: number } | undefined) {
        const newSettings : designToClothesSettings[] = [];
        designToClothesSettings.map((item, index) => {
            if (index === data.key_secure) {
                if (save !== undefined) {
                    newSettings.push({
                        place: save.place,
                        clothing_id: data.clothing_id,
                        design_id: data.design_id,
                        ml: save.ml,
                        mt: save.mt,
                        height:save.height,
                        width: save.width,
                        price: save.price
                    });
                } else {
                    newSettings.push(undefined);
                }
            } else {
                newSettings.push(item);
            }
        });
        dispatcher(setDesignToClothing(newSettings));
    }

    function nextButton () {
        saveToStorage({
            ml: settings.margin_left,
            mt: settings.margin_top,
            width: settings.width,
            height: settings.height,
            place: data.place,
            price: 0
        });
        showNextCard(data.key_secure + 1);
    }

    function skipButton () {
        showNextCard(data.key_secure + 1);
        saveToStorage(undefined);
    }

    function lastButton () {
        saveToStorage({
            ml: settings.margin_left,
            mt: settings.margin_top,
            width: settings.width,
            height: settings.height,
            place: data.place,
            price: 0
        });
        dispatcher(sendToGenerator(designToClothesSettings));
    }

    function lastSkipButton () {
        saveToStorage(undefined);
        dispatcher(sendToGenerator(designToClothesSettings));
    }

    return (
        <div id={'step-' + data.key_secure} className={data.key_secure != 0 ? 'hidden create-page-step' : 'create-page-step'}>
            <div style={{marginLeft: "850px"}}>
                <h3>{data.key_secure + 1} / {data.step_count}</h3>

                <h3>Разместить <i><b className="black">{data.place}</b></i></h3>

                <div className="flex  gap-3">
                    {
                        data.key_secure !== 0 ? <div><button className="p-1 font-size-20 btn-secondary" onClick={() => showNextCard(data.key_secure - 1)}>Назад</button><br /></div> : <div />
                    }
                    {
                        data.step_count === data.key_secure + 1 ?
                            <div />
                            :
                            <div className="flex">
                                <button className="btn-primary p-1 font-size-20" onClick={nextButton}>
                                    Далее
                                </button>
                                <button className="p-1 font-size-20 btn-info" onClick={skipButton}>
                                    Пропустить
                                </button>
                            </div>
                    }
                    {
                        data.step_count === data.key_secure + 1 ?
                            <div className="flex">
                                <button className="p-1 font-size-20 btn-success" onClick={lastButton}>
                                    Сохранить!
                                </button>
                                <button className="p-1 font-size-20 btn-info" onClick={lastSkipButton}>
                                    Пропустить и сохранить
                                </button>
                            </div>
                            : <div />
                    }
                </div>
            </div>

            <ImageResizer
                src={data.design}
                alt={data.design}
                background={{
                    backgroundImage: "url(" + data.clothing + ")",
                    width: "800px",
                    height: "800px"
                }}
                className={"step-" + data.key_secure}
                callBackFunction={changeSize}
            />
        </div>
    );
}
