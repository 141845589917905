import {OneOrderPreview} from "../../types/types";

export default function OrderPreview ( data: {order: OneOrderPreview} ) {

    const PaymentStatus = ( paymentStatus: boolean ) => {
        if (paymentStatus) {
            return <td><span className="dot green" />  Оплачено</td>;
        }
        return <td><span className="dot red" />  Не оплачено</td>;
    }

    const CheckStatus = ( CheckStatus: boolean ) => {
        if (CheckStatus) {
            return <td><span className="dot green" />  Создан</td>;
        }
        return <td><span className="dot red" />  Не создан</td>;
    }

    const SendStatus = ( SendStatus: boolean ) => {
        if (SendStatus) {
            return <td><span className="dot green" />  Отправлено</td>;
        }
        return <td><span className="dot red" />  Не отправлено</td>;
    }

    return (
        <tr>
            <a href={`/orders/` + data.order.uniq_id }>{ PaymentStatus(data.order.payment_status) }</a>
            <a href={`/orders/` + data.order.uniq_id }><td>{ data.order.uniq_id }</td></a>
            <a href={`/orders/` + data.order.uniq_id }><td>{ data.order.sum }</td></a>
            <a href={`/orders/` + data.order.uniq_id }>{ CheckStatus(data.order.check) }</a>
            <a href={`/orders/` + data.order.uniq_id }>{ SendStatus(data.order.is_sended) }</a>
            <a href={`/orders/` + data.order.uniq_id }><td>{ data.order.trek_number }</td></a>
            <a href={`/orders/` + data.order.uniq_id }><td>{ data.order.created_at }</td></a>
        </tr>
    );
}
