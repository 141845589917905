import axios, {AxiosInstance, AxiosRequestConfig} from 'axios';
import {ADMIN_API_URL, AUTH_TOKEN_KEY_NAME} from "../const/const";

const BACKEND_URL = ADMIN_API_URL + '/';
const REQUEST_TIMEOUT = 5000;

export const getToken = (): string => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY_NAME);
    return token ?? '';
};

export const saveToken = (token: string): void => {
    localStorage.setItem(AUTH_TOKEN_KEY_NAME, token);
};

export const dropToken = () => {
    localStorage.removeItem(AUTH_TOKEN_KEY_NAME);
}


export const createAPI = (): AxiosInstance => {
    const api = axios.create({
        baseURL: BACKEND_URL,
        timeout: REQUEST_TIMEOUT,
    });

    api.interceptors.request.use(
        (config: AxiosRequestConfig) => {
        const token = getToken();

        if (token && config.headers) {
            config.headers['Session'] = token;
        }

        return config;
        },
    );

    return api;
};
