import {DesignRequest} from "../../../types/types";
import React from "react";
import priceStringify from "../../../global_functions/price_stringify";
import {IS_MOBILE} from "../../../const/const";

export default function Price (data: { params?: {clothes_id: number, design_place: number, design_size: number, size: string}, design_data?: DesignRequest, product_price?: number }) {

    let summary: number = 0;

    if (data.product_price) { summary = data.product_price; }
    else if (data.design_data?.price) { summary = data.design_data.price; }

    if (data.params && data.design_data) {
        const clothesArray = [...data.design_data.clothes];
        const designPlaces = [...data.design_data.place_variants];
        const designSizes = [...data.design_data.size_variants];

        clothesArray.sort((a, b) => {
            return a.price - b.price;
        });

        designPlaces.sort((a, b) => {
            return a.price - b.price;
        });

        designSizes.sort((a, b) => {
            return a.price - b.price;
        });

        if (data.params.clothes_id !== 0) {
            data.design_data.clothes.map((clothing) => {
                if (clothing.id == data.params?.clothes_id) {
                    summary += clothing.price;
                }
            })
        } else {
            summary += clothesArray[0].price;
        }

        if (data.params.design_place !== 0) {
            data.design_data.place_variants.map((variant) => {
                if (variant.id == data.params?.design_place) {
                    summary += variant.price;
                }
            })
        } else {
            summary += designPlaces[0].price;
        }

        if (data.params.design_size !== 0) {
            data.design_data.size_variants.map((variant) => {
                if (variant.id == data.params?.design_size) {
                    summary += variant.price;
                }
            })
        } else {
            summary += designSizes[0].price;
        }
    }

    if (data.product_price || (data.params?.clothes_id !== 0 && data.params?.design_place !== 0 && data.params?.design_size !== 0)) {
        if (IS_MOBILE) {
            return <h5 className="black">Цена: { priceStringify(summary) } ₽</h5>
        }
        return <h5 className="black"> Итоговая стоимость: { priceStringify(summary) } ₽</h5>
    }

    return <h5 className="black"> Cтоимость от { priceStringify(summary) } ₽</h5>
}