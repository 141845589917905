import {useState} from "react";
import {useAppDispatch} from "../../hooks/redux/redux";
import {login} from "../../store/api-action";

export default function LoginPage () {
    const dispatch = useAppDispatch();
    const [state, setState] = useState({login: '', password: ''});
    const loginInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState({...state, login: e.target.value})
    }
    const passInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState({...state, password: e.target.value})
    }

    return (
        <div className="main_container order" >
            <div className="site-logo">
                <a href="/" className="text-black logo-text"><span className="text-black">MKY</span> x Admin</a>
            </div>
            <input className="cart_form" type="text" onInput={ loginInputHandler } placeholder="Login" />
            <input className="cart_form" type="password" onInput={ passInputHandler } placeholder="Pass" />
            <button className="cart_form" onClick={ () => dispatch(login(state)) }>Log In</button>
        </div>
    );
}
