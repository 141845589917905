import {AppNotification} from "../const/const";

export default function appNotification(type: "success" | "warning" | "error" | "info", text: string) {
    let list = document.getElementById("notification_list");
    if (!list) setTimeout(() => appNotification(type, text), 300);
    if (!list) return;
    const listDefaults = list.getBoundingClientRect();
    const listDefaultsTop = listDefaults.top > 0 ? listDefaults.top : listDefaults.top * (-1);

    document.onscroll = function (e) {
        if (!list) return;
        if (window.scrollY >= 0) {
            list.style.top = "calc("+window.scrollY+"px + " + listDefaultsTop + "px)";
        } else {
            list.style.top = "calc("+ (window.scrollY * (-1)) +"px + " + listDefaultsTop + "px)";
        }
    }

    function hideNotify (elem: HTMLElement) {
        elem.classList.add('disabled');
        setTimeout(() => { elem.remove() }, 500);
    }

    function showNotify (elem: HTMLElement) {
        list?.appendChild(elem);
        setTimeout(() => { elem.classList.add('show') }, 100);
        setTimeout(() => { hideNotify(elem) }, 5000);
    }

    const notificationElement = document.createElement("div");
    notificationElement.classList.add("notification");

    switch (type) {
        case AppNotification.success:
            notificationElement.classList.add(AppNotification.success);
            break;
        case AppNotification.error:
            notificationElement.classList.add(AppNotification.error);
            break;
        case AppNotification.warning:
            notificationElement.classList.add(AppNotification.warning);
            break;
        case AppNotification.info:
            notificationElement.classList.add(AppNotification.info);
            break;
    }

    notificationElement.addEventListener('click', (e) => {
        e.preventDefault();
        hideNotify(notificationElement);
    })

    const textElement = document.createElement('p');
    textElement.classList.add("notification_text");
    textElement.textContent = text;

    notificationElement.appendChild(textElement);

    showNotify(notificationElement);
}