import {createAction} from '@reduxjs/toolkit';
import {
    CatalogRequest, Clothing, DesignRequest,
    designToClothesSettings,
    newDesign,
    OneOrder,
    OneOrderPreview, Product
} from "../types/types";

export const setAllOrder = createAction<OneOrderPreview[]>('data/get');

export const setOneOrder = createAction<OneOrder>('data/getOne');

export const loadDesign = createAction<DesignRequest>('data/set/design');

export const loadProduct = createAction<Product>('data/set/product');

export const setClothes = createAction<Clothing[]>('design/tools');

export const setNewDesign = createAction<newDesign | undefined>('design/new_image');

export const setDesignToClothing = createAction<designToClothesSettings[]>('design/to_clothes');

export const loadCatalog = createAction<CatalogRequest>('data/catalog');
