import {useAppDispatch} from "../../hooks/redux/redux";
import {createCatalog} from "../../store/api-action";
import {useParams} from "react-router-dom";

export default function CreateCatalogPage () {
    const dispatcher = useAppDispatch();
    const { catalog_id } = useParams();

    const sendButtonHandler = (evt: any) => {
        evt.preventDefault();
        const form = document.querySelector('.form');
        if (form && catalog_id) {
            dispatcher(createCatalog({data: form as HTMLFormElement, catalog_id: +catalog_id}));
        }
    }

    return (
        <div className="main_container">
            <form method="post" name="addItem" encType="multipart/form-data" className="form" >
                <h1 className="black">Создать каталог</h1>
                <br/>

                <h3 className="black">Название</h3>
                <input className="new_clothes__input" type="text" name="name" />

                <h3 className="black">Описание</h3>
                <textarea name="description" id="" cols={30} rows={3} />
                <br/>

                <h3 className="black">Фото каталога</h3>
                <input className="new_clothes__input" type="file" name="image" accept="image/*" />
                <br/>

                <button className="new_clothes__input" onClick={sendButtonHandler}>Отправить</button>
            </form>
        </div>
    );
}
