import {useAppDispatch, useAppSelector} from "../../hooks/redux/redux";
import {createDesign, loadAllClothing} from "../../store/api-action";
import {DEFAULT_DESIGN_PLACES} from "../../const/const";
import CreateImage from "../../components/create_image/create_image";
import {setDesignToClothing} from "../../store/action";
import {useParams} from "react-router-dom";

export default function CreateDesignPage () {
    const dispatcher = useAppDispatch();
    const { catalog_id } = useParams();

    const sendButtonHandler = (evt: any) => {
        evt.preventDefault();
        const form = document.querySelector('.form');
        if (form && catalog_id) {
            dispatcher(createDesign({data: form as HTMLFormElement, catalog_id: +catalog_id}));
            dispatcher(loadAllClothing());
        }
    }

    const tools = useAppSelector((select) => select.clothes );
    const newDesign = useAppSelector((select) => select.newDesignInfo);

    if (tools.length > 0 && newDesign && DEFAULT_DESIGN_PLACES) {

        const settings: { place: string; id: number; image: string; name: string; price: number; sizes: [string]}[] = []
        const generateTemplate : undefined[] = []

        tools.map((clothing) => {
            DEFAULT_DESIGN_PLACES.map((place) => {
                settings.push({
                    ...clothing, place:place, price: 0
                });
                generateTemplate.push(undefined);
            })
        })

        dispatcher(setDesignToClothing(generateTemplate));

        return (
            <div className="main_container">
                <h1 className="black">Генерация изображений</h1>
                <h5 className="black mt-3">Количество одежды: {tools.length}</h5>
                <h5 className="black">Количество вариантов на одежде: {DEFAULT_DESIGN_PLACES.length}</h5>
                {
                    settings.map((clothing, key) => {
                        return (
                            <div key={key}>
                                <CreateImage
                                    clothing={clothing.image}
                                    clothing_id={clothing.id}
                                    design={newDesign.design_image}
                                    design_id={newDesign?.design_id}
                                    place={clothing.place}
                                    key_secure={key}
                                    step_count={settings.length}
                                />
                            </div>

                        );
                    })
                }
            </div>
        );
    }

    return (
        <div className="main_container">
            <form method="post" name="addItem" encType="multipart/form-data" className="form" >
                <h1 className="black">Создать дизайн</h1>
                <br/>
                <h3 className="black">Название</h3>
                <input className="new_clothes__input" type="text" name="name" />
                <h3 className="black">Цена дизайна</h3>
                <input className="new_clothes__input" type="number" name="price" />
                <h3 className="black">Скидка</h3>
                <input className="new_clothes__input" type="number" name="discount" />
                <h3 className="black">Описание</h3>
                <textarea name="description" id="" cols={30} rows={3} />
                <br/>
                <h3 className="black">Фото дизайна
                    <span className="ml-5 bg-primary">*.png</span>
                    <span className="ml-5 bg-primary">800 x 800</span>
                </h3>
                <input className="new_clothes__input" type="file" name="design" accept=".png" />
                <br/>
                <button className="new_clothes__input" onClick={sendButtonHandler}>Отправить</button>
            </form>
        </div>
    );
}
