import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import Header from './components/header/header';
import Footer from './components/footer/footer';
import FirstPage from "./pages/first_page/first_page";
import { store } from './store';
import OrderPage from "./pages/order/Order";
import UpdateOrderPage from "./pages/update_order/update_order";
import LoginPage from "./pages/login/login";
import PrivateRoute from "./components/private_route/private_route";
import CreateDesignPage from "./pages/design_page/create";
import CatalogPage from "./pages/catalog/catalog_page";
import CreateCatalogPage from "./pages/catalog/create_catalog_page";
import DesignPage from "./pages/design_page/design_page";
import ProductPage from "./pages/product/product_page";
import CreateProductPage from "./pages/product/create";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);

export default function App() : JSX.Element {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/">
                    <Route path="login">
                        <Route index element ={
                            <LoginPage/>
                        }
                        />
                    </Route>
                    <Route index element = {
                        <PrivateRoute>
                            <div>
                                <Header />
                                <FirstPage />
                                <Footer />
                            </div>
                        </PrivateRoute>
                    }
                    />
                    <Route path="index.html">
                        <Route index element = {
                            <PrivateRoute>
                                <div>
                                    <Header />
                                    <FirstPage />
                                    <Footer />
                                </div>
                            </PrivateRoute>
                        } />
                    </Route>
                    <Route path="catalog/:catalog_id">
                        <Route index element = {
                            <PrivateRoute>
                                <div>
                                    <Header />
                                    <CatalogPage />
                                    <Footer />
                                </div>
                            </PrivateRoute>
                        } />
                    </Route>
                    <Route path="design/create/:catalog_id">
                        <Route index element = {
                            <PrivateRoute>
                                <div>
                                    <Header />
                                    <CreateDesignPage />
                                    <Footer />
                                </div>
                            </PrivateRoute>
                        } />
                    </Route>
                    <Route path="product/create/:catalog_id">
                        <Route index element = {
                            <PrivateRoute>
                                <div>
                                    <Header />
                                    <CreateProductPage />
                                    <Footer />
                                </div>
                            </PrivateRoute>
                        } />
                    </Route>
                    <Route path="catalog/create/:catalog_id">
                        <Route index element = {
                            <PrivateRoute>
                                <div>
                                    <Header />
                                    <CreateCatalogPage />
                                    <Footer />
                                </div>
                            </PrivateRoute>
                        } />
                    </Route>
                    <Route path="orders">
                        <Route index element = {
                            <PrivateRoute>
                                <div>
                                    <Header />
                                    <FirstPage />
                                    <Footer />
                                </div>
                            </PrivateRoute>
                        } />
                    </Route>
                    <Route path="orders/:id">
                        <Route index element = {
                            <PrivateRoute>
                                <div>
                                    <Header />
                                    <OrderPage />
                                    <Footer />
                                </div>
                            </PrivateRoute>
                        }
                        />
                    </Route>
                    <Route path="orders/update/:id">
                        <Route index element = {
                            <PrivateRoute>
                                <div>
                                    <Header />
                                    <UpdateOrderPage />
                                    <Footer />
                                </div>
                            </PrivateRoute>
                        }
                        />
                    </Route>
                    <Route path="design/:design_id">
                        <Route index element = {
                            <PrivateRoute>
                                <div>
                                    <Header />
                                    <DesignPage />
                                    <Footer />
                                </div>
                            </PrivateRoute>
                        } />
                    </Route>
                    <Route path="product/:product_id">
                        <Route index element = {
                            <PrivateRoute>
                                <div>
                                    <Header />
                                    <ProductPage />
                                    <Footer />
                                </div>
                            </PrivateRoute>
                        } />
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

root.render(<Provider store={store}><App /></Provider>);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// reportWebVitals(console.log('fasfsfd'));
