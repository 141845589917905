import {useAppDispatch, useAppSelector} from "../../hooks/redux/redux";
import {createProduct, loadAllClothing} from "../../store/api-action";
import {useParams} from "react-router-dom";
import {useEffect} from "react";

export default function CreateProductPage () {
    const dispatcher = useAppDispatch();
    const { catalog_id } = useParams();

    useEffect(() => {
        dispatcher(loadAllClothing());
    }, []);
    const clothes = useAppSelector(( state ) => state.clothes );

    const sendButtonHandler = (evt: any) => {
        evt.preventDefault();
        const form = document.querySelector('.form');
        if (form && catalog_id) {
            dispatcher(createProduct({data: form as HTMLFormElement, catalog_id: +catalog_id}));
        }
    }

    return (
        <div className="main_container">
            <form method="post" name="addItem" encType="multipart/form-data" className="form">
                <h1 className="black">Создать продукт</h1>
                <br/>
                <h3 className="black">* Название</h3>
                <input className="new_clothes__input" type="text" name="name"/>
                <h3 className="black">* Цена продукта</h3>
                <input className="new_clothes__input" type="number" name="price"/>
                <h3 className="black">* Скидка</h3>
                <input className="new_clothes__input" type="number" name="discount" defaultValue={0}/>
                <h3 className="black">* Описание</h3>
                <textarea name="description" id="" cols={30} rows={3}/>
                <br/>

                <h3 className="black">Одежда</h3>
                <div className="flex">
                    {
                        clothes.map((clothes, index) =>
                            <div>
                                <label htmlFor={String(index)}>
                                    <img src={clothes.image} alt={clothes.name} height="150px" width="150px"/>
                                </label><br/>
                                <input type="radio" value={clothes.id} id={String(index)} name="clothes_id"/>{clothes.name}
                            </div>
                        )
                    }
                </div>

                <h3 className="black">* Фото №1
                    <span className="ml-5 bg-primary">800 x 800</span>
                </h3>
                <input className="new_clothes__input" type="file" name="photo1" accept="image"/>
                <br/>

                <h3 className="black">* Фото №2
                    <span className="ml-5 bg-primary">800 x 800</span>
                </h3>
                <input className="new_clothes__input" type="file" name="photo2" accept="image"/>
                <br/>

                <h3 className="black">* Горизонтальное фото
                    <span className="ml-5 bg-primary">Чем больше разрешение, тем лучше!!!</span>
                </h3>
                <input className="new_clothes__input" type="file" name="horizontal_photo" accept="image"/>
                <br/>

                <h3 className="black">Фото №3
                    <span className="ml-5 bg-primary">800 x 800</span>
                </h3>
                <input className="new_clothes__input" type="file" name="photo3" accept="image"/>
                <br/>

                <h3 className="black">Фото №4
                    <span className="ml-5 bg-primary">800 x 800</span>
                </h3>
                <input className="new_clothes__input" type="file" name="photo4" accept="image"/>
                <br/>

                <h3 className="black">Фото №5
                    <span className="ml-5 bg-primary">800 x 800</span>
                </h3>
                <input className="new_clothes__input" type="file" name="photo5" accept="image"/>
                <br/>

                <h3 className="black">Фото №6
                    <span className="ml-5 bg-primary">800 x 800</span>
                </h3>
                <input className="new_clothes__input" type="file" name="photo6" accept="image"/>
                <br/>

                <h3 className="black">Фото №7
                    <span className="ml-5 bg-primary">800 x 800</span>
                </h3>
                <input className="new_clothes__input" type="file" name="photo7" accept="image"/>
                <br/>

                <h3 className="black">Фото №8
                    <span className="ml-5 bg-primary">800 x 800</span>
                </h3>
                <input className="new_clothes__input" type="file" name="photo8" accept="image"/>
                <br/>

                <h3 className="black">Фото №9
                    <span className="ml-5 bg-primary">800 x 800</span>
                </h3>
                <input className="new_clothes__input" type="file" name="photo9" accept="image"/>
                <br/>

                <button className="new_clothes__input" onClick={sendButtonHandler}>Отправить</button>
            </form>
        </div>
    );
}
