import Catalog from "../../components/catalog/catalog";
import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hooks/redux/redux";
import React, {useEffect} from "react";
import {getCatalog} from "../../store/api-action";
import {GlobalAppRoutes} from "../../const/const";

export default function CatalogPage() {

    const { catalog_id } = useParams();
    const dispatcher = useAppDispatch();

    const thisCatalog = useAppSelector(( state ) => state.catalog );

    useEffect(()=>{
        if (catalog_id) {
            dispatcher(getCatalog({catalog_id: Number(catalog_id)}));
        }
    }, []);

    useEffect(() => {
        document.title = 'MKY | Каталог аниме одежды';
    }, []);

    console.log(thisCatalog);

    if (thisCatalog !== undefined) {
        console.log(thisCatalog);
        return (
            <div className="main_container">
        <h1 className="cart_h1">Каталог</h1>
            <div className="breadcrumbs">
        <a href="/index.html"> Главная </a>
        {
            thisCatalog.path.map((crumb, index)=>
                <span> -&gt; <a href={"/catalog/" + crumb.id} className={ thisCatalog.path.length - 1 === index ? 'bold' : '' }> {crumb.name} </a> </span>
        )
        }
        </div>
                <div className="catalog_container">
                    {
                        thisCatalog.catalog.map((catalog) =>
                            <Catalog photo={catalog.image} name={catalog.name} url={catalog.id} type={"catalog"}
                                     key={catalog.id}/>
                        )
                    }
                    {
                        thisCatalog.products.map((catalog) =>
                            <Catalog photo={catalog.main_image} name={catalog.name} url={catalog.id} type={"product"}
                                     key={'product' + catalog.id}/>
                        )
                    }
                    {
                        thisCatalog.designs.map((catalog) =>
                            <Catalog photo={catalog.image} name={catalog.name} url={catalog.id} type={"design"}
                                     key={'design' + catalog.id}/>
                        )
                    }
                    <section className="catalog_card border-primary">
                        <a href={GlobalAppRoutes.createDesign + catalog_id}>
                            <div className="catalog_name">
                                <h4>Создать новый дизайн<br/>+</h4>
                            </div>
                        </a>
                    </section>
                    <section className="catalog_card border-warning">
                        <a href={GlobalAppRoutes.createProduct + catalog_id}>
                            <div className="catalog_name">
                                <h4>Создать новый продукт<br/>+</h4>
                            </div>
                        </a>
                    </section>
                    <section className="catalog_card border-warning">
                        <a href={GlobalAppRoutes.catalog.create + catalog_id}>
                            <div className="catalog_name">
                                <h4>Создать новый каталог<br/>+</h4>
                            </div>
                        </a>
                    </section>
                </div>
            </div>
        );
    }
    return <h1 className="p-5 black">Loading...</h1>
}