export default function Catalog (data: {photo: string, name: string, url: number, type: string}) {

    let url = ""
    switch (data.type) {
        case "catalog":
            url = '/catalog/' + data.url;
            break;
        case "design":
            url = '/design/' + data.url;
            break;
        case "product":
            url = '/product/' + data.url;
            break;
    }

    return (
        <section className="catalog_card" >
            <a href={ url }>
                <div className="catalog_image">
                    <img src={data.photo} alt={data.name} />
                </div>
                <div className="catalog_name">
                    <h4>{data.name}</h4>
                </div>
            </a>
        </section>
    );
}