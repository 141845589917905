import {AppNotificationType} from "../types/types";

export const AppRoute = {
    main: '/',
    faq: '/faq',
    offerList : 'clothes',
    oneOffer: 'items/',
    cart: 'cart',
    order: 'order',
}
//export const ADMIN_APP_URL = "http://127.0.0.1:3000";
//export const ADMIN_API_URL = "http://127.0.0.1:7000";
//export const IMG_SERVER_URL = "http://127.0.0.1:7777";

const PROTOCOL = "https://";

export const ADMIN_APP_URL = PROTOCOL + "admin.test-mky-shop.ru";
export const ADMIN_API_URL = PROTOCOL + "api.test-mky-shop.ru";
export const IMG_SERVER_URL = PROTOCOL + "img.test-mky-shop.ru";

export const IS_MOBILE = window.innerWidth <= 769;

export const changePhotoMethod = {
    main: 'main',
    secondary: 'secondary'
}

export const GlobalAppRoutes = {
    login: '/login',
    clothes: '/clothes',
    order: '/orders',
    createDesign: '/design/create/',
    createProduct: '/product/create/',
    update: '/update/',
    product: '/product/', // + id
    catalog: {
        default: '/catalog/',
        main: '/catalog/1',
        create: '/catalog/create/',
    },
}

export const  ApiRoute = {
    login: '/login',
    allClothing: '/clothing',
    createDesign: '/design/new/', // + catalog_id
    createProduct: '/product/new/', // + catalog_id
    design: '/design/', // + id
    product: '/product/', // + id
    allOrders: 'all_orders',
    catalog: '/catalog/',
    createCatalog: '/catalog/new/',
    generateAllClothes: '/design/create',
    updateProductVisible: 'product/visible/', // + id
    updateDesignVisible: 'design/visible/', // + id
    oneOffer: 'oneOffer.php?id=',
    oneOrder: '/order/', // + order_uniq_id
    updateOffer: 'updateOffer.php',
    updateOrder: 'updateOrder.php',


}

export const AUTH_TOKEN_KEY_NAME = 'MKY-ADMIN-TOKEN';

export const LOCAL_STORAGE_NAMES = {
    cart: 'MKY-SHOP',
    uniqid: 'MKY-ID'
}

export const IMG_SERVER_PATH = {
    clothes: IMG_SERVER_URL,
    designs: IMG_SERVER_URL + '/designs/',
    generated: IMG_SERVER_URL + '/generated/',
    catalog: IMG_SERVER_URL + '/catalog/',
}

export const DEFAULT_DESIGN_PLACES = ['Слева', 'Справа', 'По центру'];

export const AppNotification: AppNotificationType = {
    success: 'success',
    warning: 'warning',
    error: 'error',
    info: 'info'
}