export default function Footer () {
    return (
        <footer className="site-footer">
            <div className="main_container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-8">
                                <h2 className="footer-heading mb-4">О нас</h2>
                                <p>MKY это молодой бренд одежды, основывающийся на дизайнерской компьютерной вышивке. Мы создаем дизайны по мотивам ваших любимых аниме, мультиков и сериалов.</p>
                            </div>
                            <div className="col-md-4 ml-auto">
                                <ul className="list-unstyled">
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
