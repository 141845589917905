import {sendOrderConfig, getOrder} from "../../store/api-action";
import {useAppDispatch, useAppSelector} from "../../hooks/redux/redux";
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {UpdateType} from "../../types/types";

export default function UpdateOrderPage () {
    const dispatcher = useAppDispatch();
    const { id } = useParams();

    useEffect(()=>{
        if (id) {
            dispatcher(getOrder(id));
        }
    }, []);

    const initialState : UpdateType = {
        uniqid: "",
        track_number: "",
        is_sended: false,
        check_num: ""
    }
    const [state, setState] = useState(initialState);

    const data = useAppSelector(state => state.oneOrder);
    if (!data) {
        return <div className="main_container">Loading...</div>;

    }
    const updaterHandler = (data: UpdateType) => {
        dispatcher(sendOrderConfig(data));
    }

    if (!data) {
        return <div className="main_container">Loading...</div>;
    }

    const payment = data.payment;
    const order = data.order;
    const delivery = data.delivery;

    setState({
        uniqid: order.uniq_id,
        track_number: delivery.track_num ? delivery.track_num : "",
        is_sended: order.status === "4",
        check_num: payment.tax_receipt_url
    })

    const checkNumHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState({
            ...state,
            check_num: e.target.value
        })
    }

    const trackNumHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState({
            ...state,
            track_number: e.target.value
        })
    }

    const selectorHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setState({
            ...state,
            is_sended: e.target.value === '1'
        })
    }

    const returnButtonHandler = (input : 'track' | 'check_id' | 'status') => {
        if (input === 'track') {
            setState({
                ...state,
                track_number: initialState.track_number
            });
        } else if (input === 'check_id') {
            setState({
                ...state,
                check_num: initialState.check_num
            });
        } else if (input === 'status') {
            setState({
                ...state,
                is_sended: initialState.is_sended
            });
        }
    }

    console.log("lol")

    return (
        <div className="main_container" style={{textAlign: 'center'}}>
            <h1 className="black">Редактирование</h1>
            <h2 className="black">Номер заказа - { order.uniq_id }</h2>

            <br/><hr/><br/>

            <h4 className="black">Номер чека</h4>
            <input className="update_order" name="" value={ state.check_num } onInput={ checkNumHandler } />
            <a href="#" onClick={() => { returnButtonHandler('check_id') }}><p>Восстановить</p></a>

            <br/><hr/><br/>

            <h4 className="black">Трек номер</h4>
            <input className="update_order" type="text" value={ state.track_number } onInput={ trackNumHandler } />
            <a href="#" onClick={() => { returnButtonHandler('track') }}><p>Восстановить</p></a>

            <br/><hr/><br/>

            <h4 className="black">Статус отправления</h4>

            <select className="update_order" value={ state.is_sended? 1 : 0} onChange={ selectorHandler }>
                <option value="1"> Отправленно</option>
                <option value="0"> Не отправленно</option>
            </select>
            <a href="#" onClick={() => { returnButtonHandler('status') }}><p>Восстановить</p></a>

            <br/><hr/><br/>

            <button className="cart_form cart_confirm-button" onClick={ () => updaterHandler(state) }>Сохранить изменения</button>
            <br/><br/>
        </div>
    );
}
