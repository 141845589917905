type imageResizer = {
    src: string;
    alt: string;
    defaultWidth?: string;
    defaultHeight?: string;
    onlyProportionalResize?: boolean;
    showSize?: "always" | "on change" | "newer";
    background?: {
        backgroundImage?: string;
        height?: string;
        width?: string;
    };
    className?: string;
    callBackFunction?: any
};

type mouseLimitType = {
    maxX: number,
    maxY: number,
    minX: number,
    minY: number
};

export default function ImageResizer(data: imageResizer) {
    const src = data.src;
    const alt = data.alt;
    const size = {
        height: data.defaultHeight? data.defaultHeight : "150px",
        width: data.defaultWidth? data.defaultWidth : "150px",
    };
    const onlyProportionalResize = !!data.onlyProportionalResize;
    // const showSize = data.showSize ? data.showSize : "on change";
    const className = data.className? data.className : "";

    const topLeftManipulator = generateResizeManipulator("top-left");
    const topRightManipulator = generateResizeManipulator("top-right");
    const bottomLeftManipulator = generateResizeManipulator("bottom-left");
    const bottomRightManipulator = generateResizeManipulator("bottom-right");
    const bottomMiddleManipulator = generateResizeManipulator("bottom-middle");
    const topMiddleManipulator = generateResizeManipulator("top-middle");
    const leftMiddleManipulator = generateResizeManipulator("left-middle");
    const rightMiddleManipulator = generateResizeManipulator("right-middle");

    const itemsToRender = [
        topLeftManipulator,
        topRightManipulator,
        bottomLeftManipulator,
        bottomRightManipulator,
    ];
    if (!onlyProportionalResize) {
        itemsToRender.push(bottomMiddleManipulator, topMiddleManipulator, leftMiddleManipulator, rightMiddleManipulator);
    }

    setTimeout(() => {
        const imageBlock = document.getElementById(data.className + " ultra_image");
        const image = document.getElementById(data.className + " resizable_image");
        const parent = document.getElementById(data.className + " ultra_parent");

        if (!imageBlock || !parent || !image) return;

        itemsToRender.forEach(item => imageBlock.appendChild(item));

        image.onmousedown = function (e) {
            const imageCoords = getCoords(imageBlock);
            const parentCoords = getCoords(parent);
            const shiftY = e.pageY - imageCoords.top + parentCoords.top;
            const shiftX = e.pageX - imageCoords.left + parentCoords.left;

            imageBlock.style.position = "absolute";
            imageBlock.style.zIndex = "100";

            moveAt(e, imageBlock, shiftY, shiftX, parentCoords.height, parentCoords.width);

            document.onmousemove = function (e) {
                moveAt(e, imageBlock, shiftY, shiftX, parentCoords.height, parentCoords.width);
            }
            document.onmouseup = () => mouseUp(imageBlock);
            image.ondragstart = function () {
                return false;
            }
        }

    }, 500);


    function moveAt(e: MouseEvent, element: HTMLElement, shiftY: number, shiftX: number, parentBoxHeight: number, parentBoxWidth: number) {
        const imageLeft = e.pageX - shiftX;
        const imageTop = e.pageY - shiftY;
        const imageRight = imageLeft + element.offsetWidth;
        const imageBottom = imageTop + element.offsetHeight;
        let left;
        let top;

        if (imageLeft >= 0 && imageRight <= parentBoxWidth) {
            left = imageLeft;
        } else if (imageLeft < 0) {
            left = 0;
        } else if (imageRight > parentBoxWidth) {
            left = parentBoxWidth - element.offsetWidth;
        }

        if (imageTop >= 0 && imageBottom <= parentBoxHeight) {
            top = imageTop;
        } else if (imageTop < 0) {
            top = 0;
        } else if (imageBottom > parentBoxHeight) {
            top = parentBoxHeight - element.offsetHeight;
        }

        element.style.left = left + "px";
        element.style.top = top + "px";
    }

    function mouseUp(resizableElement: HTMLElement) {
        const resizableElementCoords = getCoords(resizableElement);

        document.onmousemove = null;
        document.onmouseup = null;
        if (data.callBackFunction) {
            data.callBackFunction({
                left: +resizableElement.style.left.slice(0, -2),
                top: +resizableElement.style.top.slice(0, -2),
                height: resizableElementCoords.bottom - resizableElementCoords.top,
                width: resizableElementCoords.right - resizableElementCoords.left
            })
        }
    }

    function getCoords (element: HTMLElement) {
        const box = element.getBoundingClientRect();
        return {
            top: window.scrollY + box.top,
            bottom: window.scrollY + box.top + box.height,
            left: window.scrollX + box.left,
            right: window.scrollX + box.left + box.width,
            height: box.height,
            width: box.width
        }
    }

    function generateResizeManipulator(position: "top-left" | "top-right" | "top-middle" | "bottom-left" | "bottom-right" | "bottom-middle" | "left-middle" | "right-middle") {
        const dot = document.createElement("div");
        dot.classList.add("image_resize_manipulator");
        dot.id = position;
        dot.onmousedown = mouseDownResizeManipulator;
        return dot;
    }

    function mouseDownResizeManipulator(e: MouseEvent) {
        // @ts-ignore
        const manipulatorId = e.target.id;
        const manipulator = document.getElementById(manipulatorId);
        const parent = document.getElementById(data.className + " ultra_parent");
        const resizableElement = document.getElementById(data.className + " ultra_image");

        if (!parent || !manipulator || !resizableElement) return;

        const resizableElementCoords = getCoords(resizableElement);
        const parentCoords = getCoords(parent);
        const heightLengthRatioToWidth = resizableElementCoords.height / resizableElementCoords.width // Коэффицент отношения высоты к ширине = Высота / ширина.
        moveResizeManipulator(e, manipulator, resizableElement, parentCoords, resizableElementCoords, heightLengthRatioToWidth);

        document.onmousemove = function (e) {
            moveResizeManipulator(e, manipulator, resizableElement, parentCoords, resizableElementCoords, heightLengthRatioToWidth);
        }
        document.onmouseup = () => {
            savePositions(resizableElement, parentCoords);
            mouseUp(resizableElement);
            document.body.style.cursor = "default";
        }
        manipulator.ondragstart = function () {
            return false;
        }
    }

    function moveResizeManipulator(e: MouseEvent, element: HTMLElement, resizableElement: HTMLElement, parentCoords:any, resizableElementCoords:any, heightLengthRatioToWidth: number) {

        const mouseLimitCoords :mouseLimitType = {maxX: 0, maxY: 0, minX: 0, minY: 0};

        switch (element.id) {
            case "left-middle":
                document.body.style.cursor = "e-resize";
                mouseLimitCoords.maxX = resizableElementCoords.right;
                mouseLimitCoords.minX = parentCoords.left;
                horizontalResizeLeftSide(e.pageX, resizableElement, mouseLimitCoords, parentCoords);
                break;
            case "right-middle":
                document.body.style.cursor = "e-resize";
                mouseLimitCoords.maxX = parentCoords.right;
                mouseLimitCoords.minX = resizableElementCoords.left;
                horizontalResizeRightSide(e.pageX, resizableElement, mouseLimitCoords);
                break;
            case "bottom-middle":
                document.body.style.cursor = "n-resize";
                mouseLimitCoords.maxY = parentCoords.bottom;
                mouseLimitCoords.minY = resizableElementCoords.top;
                verticalResizeBottomSide(e.pageY, resizableElement, mouseLimitCoords);
                break;
            case "top-middle":
                document.body.style.cursor = "n-resize";
                mouseLimitCoords.maxY = resizableElementCoords.bottom;
                mouseLimitCoords.minY = parentCoords.top;
                verticalResizeTopSide(e.pageY, resizableElement, mouseLimitCoords, parentCoords);
                break;
            case "bottom-right":
                document.body.style.cursor = "nw-resize";
                mouseLimitCoords.maxY = parentCoords.bottom;
                mouseLimitCoords.minY = resizableElementCoords.top;
                mouseLimitCoords.maxX = parentCoords.right;
                mouseLimitCoords.minX = resizableElementCoords.left;
                diagonalResizeBottomRightSide(e.pageX, e.pageY, resizableElement, mouseLimitCoords, heightLengthRatioToWidth);
                break;
            case "bottom-left":
                document.body.style.cursor = "ne-resize";
                mouseLimitCoords.maxY = parentCoords.bottom;
                mouseLimitCoords.minY = resizableElementCoords.top;
                mouseLimitCoords.maxX = resizableElementCoords.right;
                mouseLimitCoords.minX = parentCoords.left;
                diagonalResizeBottomLeftSide(e.pageX, e.pageY, resizableElement, mouseLimitCoords, parentCoords, heightLengthRatioToWidth);
                break;
            case "top-right":
                document.body.style.cursor = "ne-resize";
                mouseLimitCoords.maxY = resizableElementCoords.bottom;
                mouseLimitCoords.minY = parentCoords.top;
                mouseLimitCoords.maxX = parentCoords.right;
                mouseLimitCoords.minX = resizableElementCoords.left;
                diagonalResizeTopRightSide(e.pageX, e.pageY, resizableElement, mouseLimitCoords, parentCoords, heightLengthRatioToWidth);
                break;
            case "top-left":
                document.body.style.cursor = "nw-resize";
                mouseLimitCoords.maxY = resizableElementCoords.bottom;
                mouseLimitCoords.minY = parentCoords.top;
                mouseLimitCoords.maxX = resizableElementCoords.right;
                mouseLimitCoords.minX = parentCoords.left;
                diagonalResizeTopLeftSide(e.pageX, e.pageY, resizableElement, mouseLimitCoords, parentCoords, heightLengthRatioToWidth);
                break;
            default:
                break;
        }
    }

    function horizontalResizeLeftSide(mouseX: number, modifiedElement: HTMLElement, mouseLimitCoords: mouseLimitType, parentCoords: any) {
        modifiedElement.style.left = "auto";
        modifiedElement.style.right = parentCoords.right - mouseLimitCoords.maxX + "px";

        if (mouseX <= mouseLimitCoords.minX) {
            modifiedElement.style.width = mouseLimitCoords.maxX - mouseLimitCoords.minX + "px";
        } else if (mouseX >= mouseLimitCoords.maxX) {
            modifiedElement.style.width =  "1px";
        } else {
            modifiedElement.style.width = mouseLimitCoords.maxX - mouseX + "px";
        }
    }

    function horizontalResizeRightSide(mouseX: number, modifiedElement: HTMLElement, mouseLimitCoords: mouseLimitType) {
        if (mouseX <= mouseLimitCoords.minX) {
            modifiedElement.style.width =  "1px";
        } else if (mouseX >= mouseLimitCoords.maxX) {
            modifiedElement.style.width = mouseLimitCoords.maxX - mouseLimitCoords.minX + "px";
        } else {
            modifiedElement.style.width = mouseLimitCoords.maxX + (mouseX - mouseLimitCoords.maxX) - mouseLimitCoords.minX + "px";
        }
    }

    function verticalResizeTopSide(mouseY: number, modifiedElement: HTMLElement, mouseLimitCoords: mouseLimitType, parentCoords: any) {
        modifiedElement.style.top = "auto";
        modifiedElement.style.bottom = parentCoords.bottom - mouseLimitCoords.maxY + "px";

        if (mouseY <= mouseLimitCoords.minY) {
            modifiedElement.style.height = mouseLimitCoords.maxY - mouseLimitCoords.minY + "px";
        } else if (mouseY >= mouseLimitCoords.maxY) {
            modifiedElement.style.height =  "1px";
        } else {
            modifiedElement.style.height = mouseLimitCoords.maxY - mouseY + "px";
        }
    }

    function verticalResizeBottomSide(mouseY: number, modifiedElement: HTMLElement, mouseLimitCoords: mouseLimitType) {
        if (mouseY <= mouseLimitCoords.minY) {
            modifiedElement.style.height =  "1px";
        } else if (mouseY >= mouseLimitCoords.maxY) {
            modifiedElement.style.height = mouseLimitCoords.maxY - mouseLimitCoords.minY + "px";
        } else {
            modifiedElement.style.height = mouseLimitCoords.maxY + (mouseY - mouseLimitCoords.maxY) - mouseLimitCoords.minY + "px";
        }
    }

    function horizontalProportionalResizeSizeLimitBugHandler(modifiedElement: HTMLElement, mouseLimitCoords: mouseLimitType, heightLengthRatioToWidth: number) {
        let newHeight = modifiedElement.offsetWidth * heightLengthRatioToWidth;
        if (mouseLimitCoords.minY + newHeight > mouseLimitCoords.maxY) {
            newHeight = mouseLimitCoords.maxY - mouseLimitCoords.minY;
            modifiedElement.style.height = newHeight + "px";
            modifiedElement.style.width = newHeight / heightLengthRatioToWidth + "px";
        } else {
            modifiedElement.style.height = newHeight + "px";
        }
    }

    function verticalProportionalResizeSizeLimitBugHandler(modifiedElement: HTMLElement, mouseLimitCoords: mouseLimitType, heightLengthRatioToWidth: number) {
        let newWidth = modifiedElement.offsetHeight / heightLengthRatioToWidth;
        if (mouseLimitCoords.minX + newWidth > mouseLimitCoords.maxX) {
            newWidth = mouseLimitCoords.maxX - mouseLimitCoords.minX;
            modifiedElement.style.width = newWidth + "px";
            modifiedElement.style.height = newWidth * heightLengthRatioToWidth + "px";
        } else {
            modifiedElement.style.width = modifiedElement.offsetHeight / heightLengthRatioToWidth + "px";
        }
    }

    function diagonalResizeTopLeftSide(mouseX: number, mouseY: number, modifiedElement: HTMLElement, mouseLimitCoords: mouseLimitType, parentCoords: any, heightLengthRatioToWidth: number) {
        if (mouseX - mouseLimitCoords.minX < mouseY - mouseLimitCoords.minY) {
            horizontalResizeLeftSide(mouseX, modifiedElement, mouseLimitCoords, parentCoords);
            horizontalProportionalResizeSizeLimitBugHandler(modifiedElement, mouseLimitCoords, heightLengthRatioToWidth);
        } else {
            verticalResizeTopSide(mouseY, modifiedElement, mouseLimitCoords, parentCoords);
            verticalProportionalResizeSizeLimitBugHandler(modifiedElement, mouseLimitCoords, heightLengthRatioToWidth);
        }
        modifiedElement.style.bottom = parentCoords.bottom - mouseLimitCoords.maxY + "px";
        modifiedElement.style.right = parentCoords.right - mouseLimitCoords.maxX + "px";
        modifiedElement.style.left = "auto";
        modifiedElement.style.top = "auto";
    }

    function diagonalResizeTopRightSide(mouseX: number, mouseY: number, modifiedElement: HTMLElement, mouseLimitCoords: mouseLimitType, parentCoords: any, heightLengthRatioToWidth: number) {
        if (mouseX - mouseLimitCoords.minX < mouseY - mouseLimitCoords.minY) {
            horizontalResizeRightSide(mouseX, modifiedElement, mouseLimitCoords);
            horizontalProportionalResizeSizeLimitBugHandler(modifiedElement, mouseLimitCoords, heightLengthRatioToWidth);
        } else {
            verticalResizeTopSide(mouseY, modifiedElement, mouseLimitCoords, parentCoords);
            verticalProportionalResizeSizeLimitBugHandler(modifiedElement, mouseLimitCoords, heightLengthRatioToWidth);
        }
        modifiedElement.style.left = mouseLimitCoords.minX - parentCoords.left + "px";
        modifiedElement.style.bottom = parentCoords.bottom - mouseLimitCoords.maxY + "px";
        modifiedElement.style.right = "auto";
        modifiedElement.style.top = "auto";
    }

    function diagonalResizeBottomLeftSide(mouseX: number, mouseY: number, modifiedElement: HTMLElement, mouseLimitCoords: mouseLimitType, parentCoords: any, heightLengthRatioToWidth: number) {
        if (mouseX - mouseLimitCoords.minX < mouseY - mouseLimitCoords.minY) {
            horizontalResizeLeftSide(mouseX, modifiedElement, mouseLimitCoords, parentCoords);
            horizontalProportionalResizeSizeLimitBugHandler(modifiedElement, mouseLimitCoords, heightLengthRatioToWidth);
        } else {
            verticalResizeBottomSide(mouseY, modifiedElement, mouseLimitCoords);
            verticalProportionalResizeSizeLimitBugHandler(modifiedElement, mouseLimitCoords, heightLengthRatioToWidth);
        }
        modifiedElement.style.top = mouseLimitCoords.minY - parentCoords.top + "px";
        modifiedElement.style.right = parentCoords.right - mouseLimitCoords.maxX + "px";
        modifiedElement.style.left = "auto";
        modifiedElement.style.bottom = "auto";
    }

    function diagonalResizeBottomRightSide(mouseX: number, mouseY: number, modifiedElement: HTMLElement, mouseLimitCoords: mouseLimitType, heightLengthRatioToWidth: number) {
        if (mouseX - mouseLimitCoords.minX < mouseY - mouseLimitCoords.minY) {
            horizontalResizeRightSide(mouseX, modifiedElement, mouseLimitCoords);
            horizontalProportionalResizeSizeLimitBugHandler(modifiedElement, mouseLimitCoords, heightLengthRatioToWidth);
        } else {
            verticalResizeBottomSide(mouseY, modifiedElement, mouseLimitCoords);
            verticalProportionalResizeSizeLimitBugHandler(modifiedElement, mouseLimitCoords, heightLengthRatioToWidth);
        }
    }

    function savePositions (resizableElement:HTMLElement, parentCoords:any) {
        const resizableElementCoords = getCoords(resizableElement);

        const left = resizableElementCoords.left - parentCoords.left;
        const right = parentCoords.right - resizableElementCoords.right;
        const top = resizableElementCoords.top - parentCoords.top;
        const bottom = parentCoords.bottom - resizableElementCoords.bottom;

        resizableElement.style.left = left + "px";
        resizableElement.style.right = right + "px";
        resizableElement.style.top = top + "px";
        resizableElement.style.bottom = bottom + "px";
    }


    return (
        <div className="ultra_div" id={className + " ultra_parent"} style={{backgroundImage: data.background?.backgroundImage, width: data.background?.width, height: data.background?.height}}>
            <div id={className + " ultra_image"} style={{width: size.width, height: size.height}}>
                <img src={ src } id={className + " resizable_image"} className="ultra_image" alt={ alt } />
            </div>
        </div>
    )
}