import React from 'react';
import {Svg} from "../../components/svg/svg";

export const onloadHandler = () => {
    document.querySelector('.loading')?.classList.remove('loading');
    document.querySelector('.loader_page')?.classList.add('loading');
}

export default function Loader (): JSX.Element {
    return (
        <div className="loader_page">
            <div className="icon_handler">
                <Svg type={'loader'} />
            </div>
        </div>
    );
}
