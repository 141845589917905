import {GlobalAppRoutes} from "../../const/const";
import {dropToken} from "../../api/api";

export default function Header ():JSX.Element {
    return(
        <div className="site-wrap" id="home-section">
            <div className="site-mobile-menu site-navbar-target">
                <div className="site-mobile-menu-header">
                    <div className="site-mobile-menu-close mt-3">
                        <span className="icon-close2 js-menu-toggle" />
                    </div>
                </div>
                <div className="site-mobile-menu-body" />
            </div>


            <div className="top-bar">
                <div className="main_container">
                    <div><p className="marquee">📦 Бесплатная доставка 📦</p></div>
                </div>
            </div>

            <header className="site-navbar js-sticky-header site-navbar-target" role="banner">

                <div className="main_container">
                    <div className="row align-items-center position-relative">
                        <div className="site-logo">
                            <a href="/" className="text-black logo-text"><span className="text-black">MKY</span> x Admin</a>
                        </div>
                        <div className="col-12">
                            <nav className="site-navigation text-right ml-auto " role="navigation">

                                <ul className="site-menu main-menu js-clone-nav ml-auto d-none d-lg-block">
                                    <li><a href={GlobalAppRoutes.createDesign} className="nav-link">Создать</a></li>

                                    <li><a href={GlobalAppRoutes.catalog.main} className="nav-link">Создать + </a></li>

                                    <li><a href={GlobalAppRoutes.clothes} className="nav-link">Одежда</a></li>

                                    <li><a href={GlobalAppRoutes.order} className="nav-link">Заказы</a></li>

                                    <li><a href={GlobalAppRoutes.login} onClick={dropToken}>Выйти</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    );
}
