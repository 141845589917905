import {GlobalAppRoutes} from "../../const/const";
// @ts-ignore
import { getToken } from "../../api/api";

type privateRouteType = {
    children: JSX.Element;
};

// @ts-ignore
export default function PrivateRoute ({children}: privateRouteType) {
    if (getToken()) {
        return children;
    }
    window.location.assign(GlobalAppRoutes.login);
    return <div />;
}
