import {DesignRequest} from "../../../types/types";
import React from "react";
import {IS_MOBILE} from "../../../const/const";

export default function Photo (data: { params?: {clothes_id: number, design_place: number, design_size: number, size: string}, design_data?: DesignRequest, product_images?: string[] }) {

    const images: any[] = [];
    let mainImage: string = "";

    if (data.design_data) {
        mainImage = data.design_data.image;
    } else if (data.product_images) {
        mainImage = data.product_images[0]
    }

    function shuffle(array: any[]) {
        let currentIndex = array.length,  randomIndex;
        while (currentIndex != 0) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }
        return array;
    }

    if (data.params && data.design_data) {
        data.design_data.place_variants.map((variant) => {
            if (data.params && data.params.clothes_id === 0) {
                images.push(variant.image);
            } else if (data.params && data.params.design_place !== 0) {
                if (data.params.design_place == variant.id ) {
                    images.push(variant.image);
                }
            } else {
                if (data.params && data.params.clothes_id == variant.clothes_id ) {
                    images.push(variant.image);
                }
            }
        })
    } else if (data.product_images) {
        data.product_images.map((image_src, index) => {
            if (index !== 0) {
                images.push(image_src);
            }
        })
    }

    shuffle(images);

    const imagesResult = [];
    for (let i = 0; i < images.length; i += 3) {
        const group = [];
        for (let j = 0; j <= 2; j++) {
            if (images[j + i]) {
                group.push(images[j + i]);
            }
        }
        imagesResult.push(group);
    }

    if (IS_MOBILE) {
        return <div className="design_page_images_container d-flex">
            <img className="p-1" src={mainImage} alt=""/>
            {
                imagesResult.map((imagesGroup, index) => {
                    return <img className="p-1" src={ imagesGroup[0] } alt="" key={"photo" + index} />
                })
            }
        </div>
    }

    return <div className="design_page_images_container">
        <img className="p-1" src={mainImage} alt="" width="100%"/>
        {
            imagesResult.map((imagesGroup, index) => {
                if (imagesGroup.length === 3) {
                    return <div key={"photo" + index}>
                        <div className="d-flex mw-100 design_page_images_flexbox">
                            <div className="p-1 w-50">
                                <img src={ imagesGroup[0] } alt="" width="100%"/>
                            </div>
                            <div className="p-1 w-50">
                                <img src={ imagesGroup[1] } alt="" width="100%"/>
                            </div>
                        </div>
                        <img className="p-1" src={ imagesGroup[2] } alt="" width="100%"/>
                    </div>
                } else if (imagesGroup.length === 2) {
                    return <div className="d-flex mw-100 design_page_images_flexbox"  key={"photo" + index}>
                        <div className="p-1 w-50">
                            <img src={ imagesGroup[0] } alt="" width="100%"/>
                        </div>
                        <div className="p-1 w-50">
                            <img src={ imagesGroup[1] } alt="" width="100%"/>
                        </div>
                    </div>
                } else {
                    return <img className="p-1" src={ imagesGroup[0] } alt="" width="100%"  key={"photo" + index} />
                }
            })
        }
    </div>
}
